/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
  background: url("../assets/counts-bg.jpg") center center no-repeat;
  background-size: cover;
  padding: 80px 0 60px 0;
  position: relative;
}

.counts::before {
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.counts .title {
  position: relative;
  color: #fff;
  margin-bottom: 40px;
}

.counts .title h3 {
  font-size: 36px;
  font-weight: 700;
}

.counts .counters span {
  font-size: 48px;
  display: block;
  color: var(--primary);
}

.counts .counters p {
  padding: 0;
  margin: 0 0 20px 0;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  color: #fff;
}
