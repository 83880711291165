/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 0 35px 0;
  list-style: none;
  text-align: center;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  margin: 0 15px 15px 0;
  display: inline-block;
  padding: 10px 20px;
  font-size: 12px;
  line-height: 20px;
  color: #444444;
  border-radius: 4px;
  text-transform: uppercase;
  background: #fff;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  background: var(--primary);
  color: #fff;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  transition: 0.3s;
  position: relative;
  overflow: hidden;
}

.portfolio .portfolio-wrap img {
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  transition: 0.3s;
  text-align: center;
  background: rgba(53, 65, 68, 0.6);
  padding-bottom: 30px;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
}

.portfolio .portfolio-wrap .portfolio-info .portfolio-links {
  display: flex;
}

.portfolio .portfolio-wrap .portfolio-info .portfolio-links a {
  color: var(--primary);
  margin: 0 4px;
  background-color: #fff;
  border-radius: 50px;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.portfolio .portfolio-wrap .portfolio-info .portfolio-links a i {
  font-size: 18px;
  line-height: 0;
}

.portfolio .portfolio-wrap .portfolio-info .portfolio-links a:hover {
  background: var(--primary);
  color: #fff;
}

.portfolio .portfolio-wrap:hover {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.16);
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
  padding-bottom: 0;
}

.portfolio .portfolio-wrap:hover img {
  transform: scale(1.1);
}
