/*--------------------------------------------------------------
  # About List
  --------------------------------------------------------------*/
.about-list {
  padding-top: 0;
}

.about-list .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 5px 0 10px 60px;
}

.about-list .icon-box i {
  font-size: 48px;
  float: left;
  color: var(--primary);
}

.about-list .icon-box p {
  font-size: 15px;
  color: #848484;
  margin-left: 60px;
}

.about-list .image {
  background: url("../assets/brisbane-4.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 400px;
}
