/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  margin-bottom: 20px;
  padding: 30px;
  border-radius: 6px;
  background: #fff;
}

.services .icon-box i {
  float: left;
  color: var(--primary);
  font-size: 40px;
  line-height: 0;
}

.services .icon-box h4 {
  margin-left: 70px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.services .icon-box h4 a {
  color: #354144;
  transition: 0.3s;
}

.services .icon-box:hover h4 a {
  color: var(--primary);
}

.services .icon-box p {
  margin-left: 70px;
  line-height: 24px;
  font-size: 14px;
}
