@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;600;900&family=ZCOOL+XiaoWei&display=swap");

/*--------------------------------------------------------------
# Global
--------------------------------------------------------------*/
:root {
  --primary: #008987;
  --secondary: #e7f7f4;
  --primaryHover: #0a555b;
}

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

body {
  font-family: "ZCOOL XiaoWei", serif !important;
  font-family: "Poppins", sans-serif !important;
  color: #444444;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #fff;
}

a {
  color: var(--primary);
  text-decoration: none !important;
}

a:hover {
  color: var(--primaryHover);
  text-decoration: none;
}
