/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  height: 70px;
  z-index: 997;
  transition: all 0.5s;
  background: #fff;
  top: 44px;
}

#header.header-scrolled {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  top: 0;
}

#header .logo h1 {
  font-size: 28px;
  margin: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1px;
}

#header .logo h1 a,
#header .logo h1 a:hover {
  color: #354144;
  text-decoration: none;
}

#header .logo h1 a span,
#header .logo h1 a:hover span {
  color: #01b1d7;
}

#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 40px;
}
